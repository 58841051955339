import firebase from 'firebase/app'
import 'firebase/firestore'
import { Room, Wish, Invitee } from '@/types/wishlist'

firebase.firestore().settings({
  // undefinedなプロパティを自動的に削除
  ignoreUndefinedProperties: true,
})

const wishConverter: firebase.firestore.FirestoreDataConverter<Wish> = {
  toFirestore(wish: Wish) {
    return {
      name: wish.name,
      price: wish.price,
      url: wish.url,
      image: wish.image,
      images: wish.images,
      state: wish.state ?? 'none',
    }
  },
  fromFirestore(snapshot, options): Wish {
    const data = snapshot.data(options) as firebase.firestore.DocumentData
    return {
      id: snapshot.id,
      name: data.name,
      price: data.price,
      url: data.url,
      image: data.image,
      images: data.images,
      state: data.state,
    }
  },
}

const roomConverter: firebase.firestore.FirestoreDataConverter<Room> = {
  toFirestore: function (room: Room) {
    return {
      name: room.name,
      amount: room.amount,
      roles: room.roles,
      scope: room.scope,
      // wishes is firebase collection type.
      // wishes: room.wishes,
    }
  },
  fromFirestore: function (snapshot, options): Room {
    const data = snapshot.data(options) as firebase.firestore.DocumentData
    return {
      id: snapshot.id,
      name: data.name,
      amount: data.amount,
      roles: data.roles,
      scope: data.scope ? data.scope : 'private',
      wishes: [],
    } as Room
    // return new Room(snapshot.id, data.name, data.roles)

    // if (data) {
    //   const wishes: Wish[] = []
    //   // const subCollection = await snapshot.ref
    //   //   .collection('wishes')
    //   //   .withConverter(wishConverter)
    //   //   .get()
    //   // subCollection.forEach((sc) => {
    //   //   const scData = sc.data()
    //   //   if (scData) {
    //   //     wishes.push(new Wish(scData.name))
    //   //   }
    //   // })
    //   /*
    // snapshot.ref
    //   .collection('wishes')
    //   .withConverter(wishConverter)
    //   .get()
    //   .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //       const scData = doc.data()
    //       if (scData) {
    //         console.log(scData)
    //         wishes.push(scData)
    //       }
    //     })
    //   })
    //   */
    //   //  snapshot.ref.collection('wishes').withConverter(wishConverter).get().then((sc) => {

    //   //  })
    //   // return new Room(data.name, data.roles, wishes, snapshot.ref)
    //   return new Room(snapshot.id, data.name, data.roles, wishes)
    // }
    // return null
  },
}

const inviteeConverter: firebase.firestore.FirestoreDataConverter<Invitee> = {
  toFirestore: function (invitee: Invitee) {
    return {
      rooms: invitee.rooms,
    }
  },
  fromFirestore: function (snapshot, options): Invitee {
    const data = snapshot.data(options) as firebase.firestore.DocumentData
    return {
      rooms: data.rooms,
    } as Invitee
  },
}

export { wishConverter, roomConverter, inviteeConverter }
